import { graphql, StaticQuery } from 'gatsby'
import React, { useState } from 'react'
import Theme from '../components/Theme/Theme'
import Layout from '../components/VerticalPages'
import SEO from "./seo"

const query = graphql`
    query ManifestoQuery {
        page: sanityPage(_id: { regex: "/(drafts.|)manifesto/" }) {
            _type
            id
            links {
                ... on SanityArticle {
                    id
                  title
                  slug {
                    current
                  }
                }
              }

        }
    }
`

const ManifestoContainer = ({children, ...props}) => {

    return (
        <Theme>
            <SEO title={props.title} /> 
            <StaticQuery
                query={query}
                render={data => {

                    const pages = data && data.page && data.page.links.map((page, index) => {
                        return {
                            ...page,
                            title: index+1 + ". " + page.title
                        }
                    })

                    return (
                        <Layout pages={pages} {...props}>
                            {children}
                        </Layout>
                    )

            }} />
        </Theme>
    )

}

export default ManifestoContainer
